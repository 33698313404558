<template>
  <div class="normal-text bg-dark-theme text-center">
    <div class="bg-1" :class="{ 'image-loading': !bg1ImageLoaded }">
      <div class="d-block m-0">
        <LandingPageHeader :trasparentHeader="true" />
        <div class="clearfix"></div>
      </div>
      <div v-if="!bg1ImageLoaded" class="main_loader">
        <div class="img-loader"></div>
      </div>
      <div class="h-8vh h-md-0"></div>
      <section v-if="bg1ImageLoaded" class="landing_view h-md-100vh">
        <div class="row d-flex align-items-start justify-content-end">
          <div class="col-12 col-md-10">
            <div class="row">
              <div class="col-12 mt-5">
                <h1 class="large-text fnt-tny text-uppercase text-center">
                  <div>{{ $t('mom shop') }}</div>
                  <div>{{$t('AI-Powered All-in-One Business Management Software')}}</div>
                </h1>
                <h2 class="large-text-2 hide_for_mobile">
                  <p class="mr-2 fs-md-1p625rem">{{ $t('Simple, Flexible, and Powerful ERP Solution with Integrated Order Management, Cloud POS, HRMS, Accounting, Marketing, and Delivery Management for Every Business') }}</p>
                </h2>
              </div>
              <div class="col-12">
                <b-link class="custom-design custom-design-black px-md-5 font-weight-bold tra-black-bg" :to="shopRedirect">{{ $t('SIGN UP FOR FREE') }}</b-link>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <div>{{ $t('No credit card required!') }}</div>
              </div>
              <div class="col-12">
                <b-link href="https://play.google.com/store/apps/details?id=com.momitnow.twa" target="_blank">
                  <b-img :src="`${$apiUrl}/images/landing/google-play-store.png`" class="gplay_img" fluid alt="Google Play Store" />
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-center text-uppercase text-light">
            {{ $t('Your All-in-One Solution for Business Growth') }}
          </h1>
          <h2 class="large-text-2">{{ $t('MOM Shop App empowers retail businesses with essential tools and features for success in the digital age') }}</h2>
        </div>
      </div>
      <div class="h-md-3vh"></div>
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row">
            <div class="col-12 col-md-4 mb-1 mb-md-0">
              <div class="row d-flex justify-content-center">
                <div class="col-10">
                  <b-img :src="`${$apiUrl}/images/landing/Website/MOM Shop.webp`" class="home_img" fluid />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8 text-left d-flex align-items-center">
              <div>
                <p class=""> 
                  <strong>{{ $t('Instant Visibility') }}: </strong
                  >{{ $t('Showcase your products to a vast online audience, gaining immediate visibility and attracting potential customers effortlessly') }}
                </p>
                <p class=""> 
                  <strong>{{ $t('Effortless Shopping') }}: </strong
                  >{{ $t('Enhance the customer experience with our intuitive, user-friendly interface, enabling quick and easy product browsing and purchasing') }}
                </p>
                <p class=""> 
                  <strong>{{ $t('Local & Global Reach') }}: </strong>{{ $t('Expand your customer base both locally and globally') }}, {{ $t('connecting with clients worldwide and tapping into new markets') }}
                </p>
                <p class=""> 
                  <strong>{{ $t('Secure Payments') }}: </strong>{{ $t('Ensure secure, hassle-free transactions with funds directly deposited into your company account for smooth cash flow') }}
                </p>
                <p class=""> 
                  <strong>{{ $t('Data-Driven Growth') }}: </strong
                  >{{ $t('Utilize powerful analytics tools to make informed decisions, track performance, analyze trends') }}, {{ $t('and refine your offerings for maximum profitability') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('TAKE YOUR BUSINESS LIVE IN JUST 60 SECONDS, IN 3 EASY STEPS') }}
          </h1>
        </div>
      </div>
      <div class="h-md-3vh"></div>
      <div class="row">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row landing_items_view">
            <div class="col-12 col-md-4 landing_item" v-for="(item, ik) in landingItems" :key="`ik_${ik}`">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6 col-md-8 mx-auto">
                      <b-img fluid :src="`${$apiUrl}/images/landing/shop/${item.img}`" :alt="item.imgAlt" />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-px-5">
                  <b-link class="custom-design" :to="shopRedirect">{{ item.title }}</b-link>
                  <span v-for="(itemp, ikp) in item.text" :key="`itp_${ikp}_${ik}`">
                    <p class="text-muted" v-html="itemp"></p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Solution for Every Business') }}
          </h1>
        </div>
      </div>
      <div class="row landing_grids">
        <div class="col-12 col-md-4 grid_items" v-for="(menu, index) in servicesList" :key="`l_card_${index}`">
          <div class="ai_widget_img">
            <b-img fluid :src="`${$apiUrl}/images/landing-shop/${menu.image}`" class="home_img" />
          </div>
          <b-link class="ai_widget_label custom-design mx-auto" :to="shopRedirect">{{ $t(menu.title) }}</b-link>
        </div>
      </div>
    </section>

    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t("Choose MOM for Your Online Store - Here's Why") }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-10 mx-auto text-center">
          <ol class="why_mom_listing" start="1">
            <li>{{ $t('Keep All Your Profits: With 0% commission, MOM Shop encourages growth by letting you keep all your earnings') }}</li>
            <li>
              {{ $t('Insights for Growth: Our robust analytics and reporting tools enable you to understand your business performance') }}, {{ $t('helping you optimize operations and boost profitability') }}
            </li>
            <li>{{ $t('Smooth Deliveries: Handle all your orders seamlessly with our integrated delivery management system (DMS)') }}</li>
            <li>{{ $t('More Than Just E-commerce: In the hospitality industry? Extend your services with our table booking feature') }}</li>
            <li>{{ $t('Direct Bank Payments: Facilitate fast, direct payments to your bank') }}, {{ $t('ensuring smooth cash flow') }}
            </li>
            <li>{{ $t('Intuitive Order Dashboard: Stay on top of your orders and customer requests with our organized and user-friendly dashboard') }}</li>
            <li>{{ $t('Promotions & Loyalty Programs: Increase sales and customer loyalty effortlessly with our easy-to-manage promotional and loyalty programs') }}</li>
            <li>{{ $t('Effortless Transactions: Our Cloud POS system simplifies transactions, receipt generation, and financial management') }} {{ $t('also supports integration with POS like Petpooja') }}</li>
            <li>{{ $t('Automated Invoicing: Automate your invoicing process with MOM Shop and minimize error risks') }}</li>
            <li>{{ $t('Global Payment Methods: Cater to global customers by accepting payments through various platforms like Stripe, PayPal, Phonepe, Razorpay & Flutterwave') }}</li>
            <li>{{ $t('Manage Global Workforce: Efficiently onboard, track, and manage your global team with our HRMS') }}</li>
            <li>{{ $t('Sell Digital Products: Enhance your offerings with digital products like eBooks, software, and digital images') }}</li>
            <li>{{ $t('AI-Powered Marketing: Let our AI generator take care of crafting catchy images, posts, ads, and blogs') }}, {{ $t('elevating your online branding') }}</li>
            <li>{{ $t('Seamless Barcode Management: With our included barcode generator & scanner') }}, {{ $t('dealing with additional devices becomes a thing of the past!') }}</li>
            <li>{{ $t('Track Your Finances: With our accounting module') }}, {{ $t('easily track transfers, deposits, and expenses') }}</li>
          </ol>
        </div>
      </div>
    </section>
    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 text-uppercase text-center mb-2">
            {{ $t('Sign Up Today!') }}
          </h1>
          <h2 class="large-text-2">{{ $t('Experience seamless business growth with MOM Shop App') }} {{ $t('Sign up now to get started!') }}</h2>
        </div>
        <div class="col-12 d-flex justify-content-center mt-2">
          <b-link class="custom-design custom-design-black custom-design-fw text-light font-weight-bold" :to="shopRedirect">{{ $t('SIGN UP FOR FREE') }}</b-link>
        </div>
      </div>
    </section>
    <section class="landing_section text-center">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center text-light">
            {{ $t('Pricing') }}
          </h1>
          <p>
            {{ $t('Free forever') }}, {{ $t('No credit card required') }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-11 col-md-12 mx-auto">
          <SubscriptionList :subscriptions="storeSubscriptions" @upgradeSubscription="upgradeSubscription" :upgradeBtn="`Subscribe`" />
        </div>
      </div>
    </section>

    <LandingPageFooter :trasparentFooter="true" />
    <AppFooterBlack :trasparentFooter="true" />
  </div>
</template>

<script>
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import SubscriptionList from '@/views/shared/subscription/SubscriptionList.vue'
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, dateTimeFormat, imgLoad } from '@/utilities'

export default {
  components: {
    LandingPageFooter,
    LandingPageHeader,
    AppFooterBlack,
    SubscriptionList,
  },
  directives: {
    lazyBackground: {
      bind: (el, binding) => {
        el.style.backgroundImage = `url('${binding.value}')`
      },
    },
  },
  data: () => ({
    bg1ImageLoaded: false,
  }),
  computed: {
    ...mapGetters('subscriptionPlan', ['isSubscriptionLoaded', 'storeSubscriptions', 'isSubscriptionCountryLoaded', 'countries']),
    servicesList() {
      return [
        {
          title: 'Restaurants & Cloud Kitchen',
          image: 'restaurants-cloud-kitchen.webp',
          url: '/user/store/list/restaurant',
        },
        {
          title: 'Electronics',
          image: 'electronics.webp',
          url: '/user/store/list/grocery',
        },
        {
          title: 'Fashion & Accessories',
          image: 'fashion-accessories.webp',
          url: '/user/services/subcategories/list/salon',
        },
        {
          title: 'Groceries & Mini Marts',
          image: 'groceries-mini-marts.webp',
          url: '/user/store/list/market',
        },
        {
          title: 'Digital Products',
          image: 'digital_products.webp',
          url: '/user/store/list/gifts',
        },
        {
          title: 'Gifts & Stationery',
          image: 'gifts-stationery.webp',
          url: '/user/services/subcategories/list/repairs',
        },
      ]
    },
    landingItems: {
      get() {
        return [
          {
            img: 'step1.png',
            imgAlt: 'Step 1',
            title: this.$t('STEP 1'),
            text: [
              `<strong class="text-light">
                ${this.$t('Register your store')}
              </strong>
              - ${this.$t('Enter your store name, logo, address, and other details to generate your custom store link in just 60 seconds!')}`,
            ],
          },
          {
            img: 'step2.png',
            imgAlt: 'Step 2',
            title: this.$t('STEP 2'),
            text: [
              `<strong class="text-light">
                ${this.$t('Add your products')}
              </strong>
              - ${this.$t('Easily create categories and start adding your products')}. ${this.$t('Set prices, include colors and sizes (if needed), and allow customers to browse and shop instantly')}`,
            ],
          },
          {
            img: 'step3.png',
            imgAlt: 'Step 3',
            title: this.$t('STEP 3'),
            text: [
              `<strong class="text-light">
                ${this.$t('Start Selling')}
              </strong>
              - ${this.$t('Your store is now live! Share the link via Email, WhatsApp, or Facebook with friends and family to start receiving orders immediately')}`,
            ],
          },
        ]
      },
    },
    userRedirect: {
      get() {
        if (window.SuperApp.getters._isUser()) {
          return { name: 'user-dashboard' }
        }
        return { name: 'apps-login' }
      },
    },
    shopRedirect: {
      get() {
        if (window.SuperApp.getters._isStore()) {
          return { name: 'shop-dashboard' }
        }
        return { name: 'apps-shop-login' }
      },
    },
    providerRedirect: {
      get() {
        if (window.SuperApp.getters._isProvider() && !window.SuperApp.getters._isAgent()) {
          return { name: 'provider-requests' }
        } else if (window.SuperApp.getters._isProvider() && window.SuperApp.getters._isAgent()) {
          return { name: 'provider-agent-dashboard' }
        }
        return { name: 'apps-provider-login' }
      },
    },
  },
  methods: {
    ...mapActions('subscriptionPlan', ['getSubscriptionPackages', 'getCountries']),
    isEmpty,
    dateTimeFormat,
    goToService(url) {
      if (url) {
        this.$router.push({
          path: url,
        })
      }
    },
    upgradeSubscription(subscription) {
      this.$router.push({
        name: 'apps-shop-registration',
      })
    },
  },
  async mounted() {
    const appUrl = process.env.VUE_APP_FRONT_END_BASE_URL
    const img = require('@/assets/images/bg-images/main2.webp')
    const fullImageUrl = `${appUrl}${img}`
    imgLoad(fullImageUrl).then(r => {
      this.bg1ImageLoaded = true
    })
    if (!localStorage.getItem('shopSelectedCountryId')) {
      try {
        const _ipInfo = await fetch('https://api.ipregistry.co/?key=dsnrdu52t7ii07ny')
        const ipInfo = await _ipInfo.json()
        if (!isEmpty(ipInfo?.location)) {
          if (!this.isSubscriptionCountryLoaded) {
            await this.getCountries().then(data => {
              if (data._statusCode === 200) {
                const countries = data.responseData
                const isCountryExist = countries.find(x => x.country_name == ipInfo.location.country.name)
                if (isCountryExist) {
                  localStorage.setItem('shopSelectedCountryId', isCountryExist.id)
                }
              }
            })
          } else {
            const countries = this.countries
            const isCountryExist = countries.find(x => x.country_name === ipInfo.location.country.name)
            if (isCountryExist) {
              localStorage.setItem('shopSelectedCountryId', isCountryExist.id)
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (!this.isSubscriptionLoaded) {
      await this.getSubscriptionPackages()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/landing-scoped.scss';

.bg-1 {
  background-image: url('~@/assets/images/bg-images/main2.webp');
  background-color: #040612;
  padding-bottom: 1.5rem;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing.scss';
@import '~@core/scss/base/landing-page.scss';
</style>
